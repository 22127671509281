#pilots.card {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;	
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    min-width: 800px;
}

#pilots li {
    list-style-type: none;
}

#pilots .pilots{
    margin-top: 60px;
    margin-bottom: 80px;
}

#pilots .title{
    color: #2A2C2E;	
    font-family: 'Lato', sans-serif;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 23px;
    margin-bottom: 8px;
}

#pilots .learnMore{
    text-decoration: none;
    color: #5E77FF;	
    font-family: Lato;	
    font-size: 14px;	
    font-weight: 500;	
    line-height: 15px;
}

#pilots a:visited{
    color:#5E77FF;
}

#pilots .companyRectangle{
    padding: 6px 14px 6px 14px;
    font-size: 10px;
    color: white;
    height: 25px;	
    width: 78px;	
    border-radius: 4px;	
}

#pilots .caseButton {
    background-color:#5E77FF;;
    border: none;
    color: white;
    padding: 7px 18px 7px 18px;
    text-align: center;
    text-decoration: none;
    font-size: 10px;
    border-radius: 14px; 
}

#pilots .left{
    display: table-cell;
    vertical-align: middle;
    min-width: 6rem;
    margin-top: 15px;
    float: left;
}

#pilots .center{
    display: table-cell;
    vertical-align: middle;
    min-width: 40vmax;
    width: auto;
    padding-top: 15px;
    padding-right: 1rem;
}

#pilots .right{
    display: table-cell;
    vertical-align: middle;
    width: auto;
    margin-top: 15px;
    margin-right: 40px;
    float: right;
}

#pilots .flex{
    width: 100%;
    display: table;
}

