.regulatory-card {
    margin-top: 25px;
    margin-left: 30px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;	
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    width: 25%;
    float: left;
    padding-bottom: 40px;
}