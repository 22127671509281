.bchart {
    &__custom-tick {
        font-size: 0.75rem;
        fill: $secondary;
    }

    &__custom-tick-active {
        font-size: 0.75rem;
        fill: $primary;
        font-weight: $semiBold;
    }
}

.flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type='checkbox']~svg path {
    fill: $primary;
}

// Custom styles for the filter dropdowns
.MuiFormControlLabel-root,
.MuiMenu-list {
    .Mui-selected {
        background-color: white;
    }

    .MuiFormControlLabel-label,
    .MuiListItem-root,
    .MuiListItemText-root * {
        font-family: LatoWeb;
        font-size: 14px;
    }
}

.MuiSelect-select {
    font-size: 14px;
    font-family: LatoWeb;

    &:focus {
        background-color: white;
    }
}
