@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700');


/* total width */
::-webkit-scrollbar,
::-webkit-scrollbar-button,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
    background-color: transparent;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border: 4px solid transparent;
    background-clip: content-box;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}

html{
    overflow:hidden;
}

html, body {
  background-color: #f4f5f8;
  font-family: 'Lato', sans-serif;
  min-width: 700px;
  position: relative;
  height: 100%;
    margin: 0;
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px) {
    html, body {
        min-width: 100%;
        height: auto;
    }
}

.App {
  text-align: center;
  max-width: 1080px;
  min-width: 920px;
  margin: 0 auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.content-container {
    /*margin-left: 255px;*/
    padding: 40px;
    position: fixed;
    left:255px;
    right:0px;
    top:0px;
    bottom:0px;
    overflow: scroll;
    margin-top: 78px;
}

.Header {
    margin-left: 40px;
    margin-top: 20px;
    height: 20px;
    color: #525A64;
    font-family: Lato;
    font-size: 28px;
    font-weight: 500;
    line-height: 20px;
}

.navBar{
  height: 100vh;
  overflow: scroll;
}

.transparent-header {
    font-weight: 500;
    color: #525A64;
    text-align: left;
    padding: 0 10px;
    margin-bottom: 15px;
}

.hidden {
    display: none !important;
}

.error-message > div > div {
    background-color: red;
}

.general-message > div > div {
    background-color: gray;
}

.success-message > div > div {
    background-color: green;
}

.warning-message > div > div {
    background-color: yellow;
}

#all-post-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 40% 20%;
    grid-template-rows: auto 229px 400px;
    grid-template-areas:
        "s s s"
        "f f f"
        "p p a"
        "p p t";
    margin-top: 62px;
}

#all-post-container .profile-container {
    grid-area: s;
}

#all-post-container .filter-container {
    grid-area: f;
    margin: 25px;
}

#all-post-container .create-post-container {
    display: none;
}

#all-post-container .post-container {
    grid-area: p;
    margin: 25px;
}

#all-post-container .personal-container {
    display: none;
}

#all-post-container .activity-container {
    grid-area: a;
    margin: 25px;
}

#all-post-container .trending-container {
    grid-area: t;
    margin: 25px;
}


#personal-post-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 40% 20%;
    grid-template-rows: auto 229px 400px;
    grid-template-areas:
        "s s s"
        "p p c"
        "p p a"
        "p p t";
    margin-top: 62px;
}

#personal-post-container .profile-container {
    grid-area: s;
}

#personal-post-container .filter-container {
    display: none;
}

#personal-post-container .create-post-container {
    grid-area: c;
    margin: 25px;
}

#personal-post-container .personal-container {
    grid-area: p;
    margin: 25px;
}

#personal-post-container .hidden-container {
    display: none;
}

#personal-post-container .activity-container {
    grid-area: a;
    margin: 25px;
}

#personal-post-container .trending-container {
    grid-area: t;
    margin: 25px;
}
