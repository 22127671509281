@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/layout";

#navbar {
    position: absolute;
    width: 255px;
    height: 100%;
    text-align: center;
    background-color: #FFF;
    z-index: 1;
    box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
    padding-top: 77px;

    ul {
        list-style-type: none;
        text-align: left;
        padding: 14px 0 36px 0;
        border-bottom: 1px solid #EBEFF2;
        margin-top: 0;

        ul {
            padding-left: 25px;
        }
    }

    .navbar_list li {
        height: 32px;
        margin: 16px 12px;
        border-radius: 4px;
        padding: 3px 0;

        a {
            width: 100%;
            height: 100%;
            letter-spacing: 0.3px;
            color: $secondary !important;
            opacity: 0.68;
            text-decoration: none;
            font-size: 14px;
            display: flex;
            align-items: center;

            img {
                margin: 0 35px 0 15px;
                background-color: $secondary;
                opacity: 0.68;
                width: 20px;
                height: 18px;
            }
        }

        &.selected {
            background-color: $alternate;

            a {
                opacity: 1;
                color: $primary !important;

                img {
                    background-color: $primary;
                    opacity: 1;
                }
            }
        }

        &.iln-dashboard {
            a {
                color:$iln-dark !important;

                img{
                    background-color: $iln-primary !important;
                }
            }
        }

        .falseLink {
            margin-left: 24px;
            color: inherit;
            text-decoration: none;
            line-height: 65px;
            opacity: 0.3;
            width: 100px;
            display: inline-flex;
        }

        .upgrade-pill {
            display: inline-flex;
            color: #5E76FF;
            border-color: #5E76FF;
        }
    }

    .iln-border {
        .dropdown-menu {
            border: 1px solid $iln-primary !important;
            &:before {
                content: '';
                @include arrow-up($white, 1px, $iln-primary !important);
            }
        }
    }


    .navbar_profile-container {
        text-align: left;
        display: flex;
        padding-left: 25px;
        align-items: center;
        margin-top: 30px;

        .dropdown-toggle {
            &::after {
                content: none;
            }
        }

        .dropdown-menu {
            border: 1px solid $primary;
            margin-top: 10px !important;

            &:before {
                content: '';
                @include arrow-up($white, 1px, $primary);
                position: absolute;
                top: -6px;
                left: 10px;
            }

            .dropdown-item {
                color: $secondary !important;
                padding: 0.75rem 1.5rem;

                &.selected {
                    color: $primary !important;
                }

                &:focus {
                    background: #f8f9fa;
                }
            }
        }

        img {
            width: 17px;
            background-color: $secondary;
            margin: 0 36px 0 6px;
        }

        .profile-initials {
            font-size: 12px;
            text-transform: uppercase;
            border: 1px solid $primary;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
