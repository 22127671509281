.upgradeButton div Button {
    margin-top: 5px;
    color: #5E76FF;
    border-color: #5E76FF;
}

.upgradeButton div h2 {
    margin-top: 40px;
    font-weight: normal;
}
