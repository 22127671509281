.title{
    color: #2A2C2E;	
    font-family: 'Lato', sans-serif;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 23px;
    margin-bottom: 8px;
}

.Discover{
    margin-top: 10px;
    margin-left: -60px;
    margin-bottom: 30px;
}

.discoverBlock{
    font-family: Lato;  
}

.discoverTitle{
    height: 23px;		
    color: #2A2C2E;	
    font-size: 16px;	
    font-weight: bold;	
    line-height: 23px;
    margin-top: 10px
}

.discoverContent{
    height: 40px;	
    max-width: 800px;
    max-height: 20px;
    overflow: hidden;	
    color: #2A2C2E;	
    font-size: 0.8rem;	
    font-weight: 300;	
    margin-top: 3px;
}

.discoverTopicKPI{
    margin-left: 5px;
    height: 20px;	
    width: 133px;	
    font-size: 10px;	
    line-height: 20px;
    margin-top: 10px;
    
}

.discoverAnswers{
    height: 23px;	
    color: #939EAB;	
    font-size: 9px;	
    line-height: 23px;
}

li {
    list-style-type: none;
}

.discoverLine{
    height: 23px;	
    color: #939EAB;	
    font-size: 12px;	
    line-height: 23px;
    margin-left: 5px;
}
