.trending-card {
    margin-top: 40px;
    margin-left: 30px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;	
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    width: 25%;
    float: left;
    padding-bottom: 40px;
}

.trendingTitle{
    color: #2A2C2E;	
    font-family: 'Lato', sans-serif;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 23px;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-top: 10%;
}

.smallTopicTrending{	
    color: #2A2C2E;	
    font-family: Lato;	
    font-size: 12px;	
    font-weight: 500;	
    margin-left: 30px;
    max-height: 35px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}

.trendingImage{
    float: left;
    height: 8vh;
    width: 9vh;
    margin-right: 13px;
}

.cellTrending{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 12%;
}

.trendingKPI{
    color: #5E77FF;	
    font-family: Lato;	
    font-size: 8px;	
    margin-left: 5px;
}

.trendingLine{
    color: #939EAB;	
    font-family: Lato;	
    font-size: 10px;	
    margin-left: 5px;
}

.trendingCompany{
    color: #53A8E2;	
    font-family: Lato;	
    font-size: 8px;	
}