/* Colors */
$primary: #6221EA;
$secondary: #322352;
$alternate: #EBECF0;
$light_bg: #F5F6F8;
$white: #FFFFFF;
$black: #000000;
$link: #874FFB;
$error: #E53935;
$warning: #FBC02D;
$success: #00C853;
$app_bg: #E5E5E5;
$black: #000000;
$executive-summary-bg: rgba(98, 33, 234, 0.05);
$diversity-vc-red: #ff2d2d;
$grey: rgba(50, 35, 82, 0.68);
/* Opacity levels */
$opacity-primary: 0.87;
$opacity-other1: 0.54;
$opacity-other2: 0.38;
$iln-primary: #27829E;
$iln-dark: #434967;

@mixin recharts-tooltip-override {
    .recharts-tooltip-wrapper {
        transition: none !important;
     }

     .recharts-tooltip-wrapper:empty {
         display: 'none',
     }

     .recharts-default-tooltip {
         border: 2px solid $primary !important;
         border-radius: 4px;
         font-size: 14px;
     }

     .custom-tooltip {
         background-color: $white;
         border: 2px solid $primary;
         border-radius: 4px;
         font-size: 14px;
         padding: 0.5rem;

         &__percent {
             margin: 0,
         }
         &__name {
             margin: 0;
             color: $secondary;
             opacity: $opacity-other1;
         }
     }
}
