.cardFeatured {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;
    border-bottom: none;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.00);
    min-width: 800px;
}

.title{
    color: #2A2C2E;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 8px;
}

.featured{
    margin-top: 60px;
    margin-left: 50px;
}

.topicTitle{
    height: 23px;
    color: #2A2C2E;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    margin-top: 20px;
}

.topicContent{
    height: 40px;
    max-width: 400px;
    color: #2A2C2E;
    font-family: Lato;
    font-size: 0.8rem;
    font-weight: 300;

    margin-top: 8px;
}

.topicKPI{
    height: 20px;
    width: 133px;
    color: #53A8E2;
    font-family: Lato;
    font-size: 10px;
    line-height: 20px;
    margin-top: 8px;
}

li {
    list-style-type: none;
}

.pill {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5E77FF;
    height: 30px;
    width: 90px;
    border-radius: 22px;
    outline: none;
    border: none;
    color: #FFF;
    font-weight: normal;
    font-weight: 500;
    font-size: 12px;
    float: right;
    margin-right: 30px;
}
