.title{
    color: #2A2C2E;	
    font-family: 'Lato', sans-serif;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 23px;
    margin-bottom: 8px;
}

.Answer{
    margin-top: 10px;
    margin-left: -60px;
    margin-bottom: 30px;
}

.answerBlock{
    font-family: Lato;  
}

.answerTitle{
    height: 23px;		
    color: #2A2C2E;	
    font-size: 16px;	
    font-weight: bold;	
    line-height: 23px;
    margin-top: 10px
}

.answerContent{
    height: 40px;	
    max-width: 800px;
    max-height: 20px;
    overflow: hidden;	
    color: #2A2C2E;	
    font-size: 0.8rem;	
    font-weight: 300;	
    margin-top: 3px;
}

.answerTopicKPI{
    margin-left: 5px;
    height: 20px;	
    width: 133px;	
    font-size: 10px;	
    line-height: 20px;
    margin-top: 10px;
    
}

.answerAnswers{
    height: 23px;	
    color: #939EAB;	
    font-size: 9px;	
    line-height: 23px;
}

li {
    list-style-type: none;
}

.answerLine{
    height: 23px;	
    color: #939EAB;	
    font-size: 12px;	
    line-height: 23px;
    margin-left: 5px;
}

/* .answerInput{
    display: none;
} */

.answerInput.answerText{
    width: 80%;
    height: 75px;
    padding: 6px 10px;
    box-sizing: border-box;
    border: 2px solid #cccccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;

}

.submitButton{
    background-color: #5E77FF;
    border: none;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    cursor: pointer;
    border-radius: 16px;
    
    outline: white;
}