.experts-card {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    min-width: 800px;
}

.expertsTitle{
    color: #2A2C2E;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 5%;
    margin-left: 5.5rem;
    margin-top: 3rem;
}

.resultsTitle{
    color: #2A2C2E;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    margin-left: 5.5rem;
}
.expertInputs{
    display: flex;
    margin-left: 90px;
    margin-top: -3rem;
}

.topicArea{
    float: left;
    margin-left: 20px;
    margin-top: 5px;
}
.locationSearch{
    width: 120px;
    margin-top: 0;
}
.searchButton{
    background-color: #5E77FF;
    border: none;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    cursor: pointer;
    border-radius: 16px;
    margin-top: 20px;
    margin-left: 35px;
    outline: white;
}
button:focus {
    outline:0;
}

.labels{
    color: rgb(167, 166, 167);
    font-weight: 400;
}
.checkBoxes{
    margin-left: 13rem;
    margin-bottom: 20px;
}

.oneExpert{
    margin-left: 40px;
    margin-right: 40px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    margin-bottom: 25px;
}

.points{
    float: right;
    font-size: 10px;
    color: #5E77FF;
    font-weight: 500;
    margin-right: 15px;
    margin-top: 4px;
}

.labelIcon{
    float: right;
    margin-top: -3px;
    margin-right: 5px;
}

.containerExperts {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
}


.profilePic{
    border-radius: 50%;
    height: 15vh;
    width: 15vh;
    object-fit: cover;
}

.textAllgin{
    color: rgb(129, 129, 129);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.leftDiv{
    margin-top: 1rem;
}

.middlDiv{
    margin-left: 5%;
    margin-top: 1rem;
    min-width: 21rem;
    width: auto;
}

.rightDiv{
    margin-left: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 20px;
    min-width: 10rem;
    width: 400px;
}

.expertName{
    color: black;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.expertCompany{
    color: #5E77FF;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.expertLocation{
    color: rgb(180, 175, 175);
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 400;
    word-spacing: 3px;
}

.expertDescription{
    color: rgb(129, 128, 128);
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 20px;
}

.expertTopic{
    border: none;
    color: rgb(73, 72, 72);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    cursor: pointer;
    border-radius: 16px;
    margin-left: 8px;
    margin-top: 10px;
    outline: white;
    float: left;
}

.topicsExperts{
    color: rgb(105, 103, 103);
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 400;
    word-spacing: 3px;
    margin-left: 8px;
    width: 400px;
}
