@import "variables";

/* Font Weights */
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;

/* Font Family */
/* Webfont: Lato-Light */@font-face {
  font-family: 'LatoWebLight';
  src: url('/fonts/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: $light;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
  font-family: 'LatoWeb';
  src: url('/fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: $regular;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */@font-face {
  font-family: 'LatoWebMedium';
  src: url('/fonts/Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: $medium;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */@font-face {
  font-family: 'LatoWebSemibold';
  src: url('/fonts/Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: $semiBold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */@font-face {
  font-family: 'LatoWebBold';
  src: url('/fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: $bold;
  text-rendering: optimizeLegibility;
}

// screen to font scale with different viewports
//Screen size   1vw	    2vw	    3vw	    4vw	    5vw
//400px	        4px	    8px	    12px	16px	20px
//500px	        5px	    10px	15px	20px	25px
//600px	        6px	    12px	18px	24px	30px
//700px	        7px	    14px	21px	28px	35px
//800px	        8px	    16px	24px	32px	40px

/* Font Variants */
h1, .h1-headline {
  font-family: 'LatoWebMedium';
  font-size: 1.5rem;
  margin: 0;
}

h2, .h2-headline {
  font-family: 'LatoWebMedium';
  font-size: 1.25rem;
  margin: 0;
}

h3, .subtitle-1 {
  font-family: 'LatoWebSemibold';
  font-size: 1rem;
  margin: 0;
}

h4, .subtitle-2 {
  font-family: 'LatoWebSemibold';
  font-size: 0.88rem;
  margin: 0;
}

h5, .caption {
  font-family: 'LatoWeb';
  font-size: 0.75rem;
  margin: 0;
}

h6 .overline {
  font-family: 'LatoWeb';
  font-size: 0.63rem;
  margin: 0;
}

p .body-1 {
  font-family: 'LatoWeb';
  font-size: 1rem;
}

small {
  font-size: 80%;
}

button,
input[type='button'] {
  font-family: 'LatoWebBold';
  font-size: 0.88rem;
}

a,
.link {
  font-family: $medium !important;
  font-size: 0.88rem;
  color: $link !important;
  text-decoration: none !important;
}

