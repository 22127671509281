.topBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    margin-right: 110px;
    margin-top: 40px;
    max-width: 15vw;
    /* max-width: 45vw; with resource and pilote tab */
}

.buttons a,
.topBar a {
    /*height: 20px;
    width: 45px; */
    color: #525A64 !important;
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.clicked a{
    /*height: 20px;
    width: 45px; */
    color: #5E77FF;
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.line{
    box-sizing: border-box;
    height: 22px;
    width: 1.5px;
    background-color: #DBD9DB;
}

.dropdown-item a{
    color: #525A64;
}

.dropdown:hover .dropdown-menu, .dropdown-menu:hover{
    display: block;
}

.dropdown {
    position: relative;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown:hover .dropdown-menu, .dropdown-menu:hover {
    display: block;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown:hover .dropdown-menu, .dropdown-menu:hover {
    display: block;
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

button, select {
    text-transform: none;
}

.dropdown-item a {
    color: #525A64;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}
