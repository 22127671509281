.popular-card {
    margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 40px;
    box-sizing: border-box;
    border: 1px solid #DBD9DB;	
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    width: 58%;
    float: left;
    height: 100%;
}
.mostPopularImg{
    width:90%;
    max-height:30vh;
}

.most-popular {
    margin-left: 10%;
    margin-bottom: 2%;
}
.popular-grid{
    display: grid;
    grid-auto-flow: row;
    margin-left: 10%;
    margin-right: 10%;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    padding: 10px;
}

.cell{
    height: 45vh;
}

.popularTitle{
    color: #2A2C2E;	
    font-family: 'Lato', sans-serif;	
    font-size: 16px;	
    font-weight: 500;	
    line-height: 23px;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-top: 5%;
}

.smallImage{
    width:100%;
    height:20vh;
    margin-bottom: 5%;
}
.smallTopic{
    color: #2A2C2E;	
    font-family: Lato;	
    font-size: 14px;	
    font-weight: bold;	
}

.popular{
    margin-top: 60px;
    margin-left: 50px;
}

.smallContent{
    height: 40px;	
    max-width: 400px;
    width: 200px;
    color: #2A2C2E;	
    font-family: Lato;	
    font-size: 12px;	
    font-weight: 200;	
    line-height: 15px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.popularTopic2{
    height: 23px;	
    color: #2A2C2E;	
    font-family: Lato;	
    font-size: 16px;	
    font-weight: bold;	
    line-height: 23px;
    margin-top: 20px;
}

.popularContent{
    height: 40px;	
    max-width: 400px;	
    color: #2A2C2E;	
    font-family: Lato;	
    font-size: 12px;	
    font-weight: 300;	
    line-height: 23px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.popularKPI{
    height: 20px;	
    color: #53A8E2;	
    font-family: Lato;	
    font-size: 10px;	
    line-height: 20px;
    margin-top: 8px;
}

.popularSource{
    height: 20px;		
    color: #5E77FF;	
    font-family: Lato;	
    font-size: 10px;	
    line-height: 20px;
    margin-top: 8px;
    margin-left: 5px;
}

li {
    list-style-type: none;
}

.popularLine{
    height: 23px;	
    color: #939EAB;	
    font-family: Lato;	
    font-size: 12px;	
    line-height: 23px;
    margin-left: 5px;
}