@import '../../styles/variables';
@import '../../styles/layout';

.report-inaccuracy-content {
  .report-inaccuracy-step {
    &.industry {
      display: flex;
      flex-direction: column;

      .MuiCheckbox-root {
        padding: 6px 9px;
      }
    }

    &.details {
      .form-group {
        display: flex;
        flex-direction: column;
      }

      label {
        font-size: 16px;
        margin: 6px 0 16px;
      }

      textarea {
        width: 100% !important;
        border: 1px solid #CED4DA;
        border-radius: 4px;
      }
    }

    &.submitted {
      text-align: center;
      color: $secondary;

      img {
        width: 81px;
      }

      h3 {
        font-family: "LatoWebSemibold";
        font-size: 1.2rem;
        margin: 0;
        padding: 0 50px;
      }

      p {
        padding: 0 50px;
      }
    }
  }

  hr {
    height: 1px;
    background-color: $alternate;
    border: none;
    margin: 13px 0;
  }

  .dialog-actions {
    display: flex;
    justify-content: flex-end;

    button:not(:last-child) {
      margin-right: 13px;
    }
  }
}